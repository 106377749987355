import React, { Component } from 'react'

import { StyleBoxVideo } from './style'

import IconPlay from '../../images/icone-play-video.svg'
import IconLapis from '../../images/icone-lapis.svg'

class ComponentVideo extends Component {
  render() {
    return (
      <StyleBoxVideo href={this.props.url_video} target="_blank">
        <div className="img_video">
          <img src={IconPlay} alt="Icone play" className="icone" />
          {this.props.img_video_optimized ? (
            this.props.img_video_optimized
          ) : (
            <img src={this.props.img_video} alt={`Imagem vídeo ${this.props.titulo_video}`} className="thumb" />
          )}
        </div>
        <div className="info">
          <h3>{this.props.titulo_video}</h3>
          <p>{this.props.descricao_video}</p>
          <span>
            <img src={IconLapis} alt="Icone acessar" /> Acessar agora
          </span>
        </div>
      </StyleBoxVideo>
    )
  }
}

export default ComponentVideo
