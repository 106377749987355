import React, { Component } from 'react'
import NavigationContainers from '../components/navigation-containers'

// Components Globals
import Header from 'components/header'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import BoxVideo from 'components/box-video'

import { BannerConteudo, SectionVideos } from '../Style/conteudos'
import { Container, SeloTitle } from '../Style/global'

// Sass Global

import 'sass/main.scss'

class PageConteudos extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Cursos" />

        <Header />

        <BannerConteudo>
          <Container className="cont">
            <SeloTitle>Conteúdos</SeloTitle>
            <h1>Conteúdos para descomplicar a gestão do seu negócio</h1>
            <NavigationContainers from={this.props.location.pathname} />
          </Container>
        </BannerConteudo>

        <SectionVideos>
          <Container>
            <div className="all">
              <BoxVideo
                url_video="https://conteudo.inovafarma.com.br/workshop-treinamento"
                img_video={`https://d335luupugsy2.cloudfront.net/cms/files/58918/1566837211/$sgi9akt42id`}
                titulo_video="Treinamento Eficiente, Farmácia Lucrativa"
                descricao_video="WORKSHOP GRATUITO em 4 VÍDEOS para melhorar o treinamento da sua equipe e engajar os colaboradores com as metas da empresa."
              />
              <BoxVideo
                url_video="https://conteudo.inovafarma.com.br/workshop-gestao-pessoas"
                img_video={`https://d335luupugsy2.cloudfront.net/cms/files/58918/1570624310/$ahz3me3au2w`}
                titulo_video="Gestão de Pessoas e Marketing na Farmácia"
                descricao_video="WORKSHOP GRATUITO em 3 VÍDEOS para melhorar o treinamento da sua equipe e engajar os colaboradores com as metas da empresa"
              />
            </div>
          </Container>
        </SectionVideos>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageConteudos
