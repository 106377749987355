import styled from 'styled-components'

import BannerImagemConteudo from 'images/banners/banner-conteudos.jpg'

export const BannerConteudo = styled.section`
  margin-top: 80px;
  background: url(${BannerImagemConteudo}) no-repeat top center;
  height: 430px;
  padding-top: 100px;
  h1 {
    font: normal bold 48px/55px 'DepotNew';
    color: #ffffff;
    max-width: 600px;
  }
  .cont {
    position: relative;
    height: 100%;
  }
  @media (max-width: 1050px) {
    margin-top: 60px;
    height: auto;
    padding: 40px 0px;
    padding-bottom: 0px;
    background-size: 1200px;
    background-position-x: 85%;
    height: 300px;
    h1 {
      font-size: 28px;
      text-align: center;
      line-height: 35px;
      max-width: 275px;
      margin: 0 auto;
    }
  }
`

export const SectionCounteudos = styled.section`
  padding-top: 80px;
  padding-bottom: 60px;
  .item-cursos {
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &.cursos {
    h2 {
      text-align: left;
      margin-bottom: 40px;
      span {
        font: normal 400 36px/40px 'DepotNew';
        color: #b1b2b3;
      }
    }
  }
  .all-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 60px;
  }
  .item-categoria-conteudo {
    margin-bottom: 103px;
    h2 {
      text-align: left;
      span {
        font: normal normal 36px/40px 'DepotNew';
        color: #b1b2b3;
      }
    }
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    &.cursos {
      h2 {
        text-align: center !important;
        font-size: 24px;
        margin-bottom: 20px;
        font-size: 24px;
        span {
          font-size: 24px;
        }
      }
    }
    .item-cursos {
      margin-bottom: 40px;
    }
    .all-box {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
    .item-categoria-conteudo {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0px;
      }
      h2 {
        text-align: center;
        font-size: 24px;
        span {
          font-size: 24px;
        }
      }
    }
  }
`

export const SectionVideos = styled.section`
  padding-top: 80px;
  padding-bottom: 60px;
  .all {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    .all {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }
`
