import React, { Component } from 'react'
import { Navigation } from './style'
import { Link } from 'gatsby'

class ComponentNavigationContainer extends Component {
  state = {}
  render() {
    return (
      <Navigation>
        <li>
          <Link to="/conteudo/cursos" className={this.props.from === '/conteudo/' ? 'active' : null} activeClassName="active">
            Cursos
          </Link>
        </li>
        <li>
          <Link to="/conteudo/e-books" activeClassName="active">
            E-books
          </Link>
        </li>
        <li>
          <Link to="/conteudo/videos" activeClassName="active">
            {' '}
            Vídeos
          </Link>
        </li>
        <li>
          <Link to="/conteudo/webinarios" activeClassName="active">
            Webinários
          </Link>
        </li>
      </Navigation>
    )
  }
}

export default ComponentNavigationContainer
