import styled from 'styled-components'

export const Container = styled.div`
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 1050px) {
    width: 100%;
  }
`

export const SeloTitle = styled.span`
  width: 268px;
  height: 42px;
  background: linear-gradient(136.57deg, #0acdf4 -41.4%, rgba(27, 210, 247, 0.0001) 99.15%);
  mix-blend-mode: normal;
  opacity: 0.9;
  display: flex;
  align-items: center;
  padding-left: 37px;
  font: normal 500 18px/22px 'DepotNew';
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 8px;
  margin-left: -35px;
  @media (max-width: 1050px) {
    margin: 0 auto;
    margin-bottom: 30px;
    h2 {
      text-align: center;
    }
    p {
      margin: 0 auto;
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0px;
  }
  label {
    font-size: 16px;
    line-height: 20px;
    color: #94969b;
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  @media (max-width: 480px) {
    label {
      font-size: 15px;
    }
  }
`

export const Input = styled.input`
  width: 100%;
  height: 55px;
  padding: 0px 20px;
  background-color: #f5f6fa;
  border-radius: 8px 0px;
  font-size: 18px;
  line-height: 22px;
  color: #636466;
  @media (max-width: 1440px) {
    height: 50px;
    font-size: 14px;
    padding: 0px 15px;
  }
`

export const FromGroupDouble = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ButtonGreen = styled.button`
  height: 50px;
  padding: 0px 57px;
  background-color: #0ac4ac;
  border: 2px solid #0ac4ac;
  border-radius: 8px 0px;
  font: normal 500 15px/18px 'DepotNew';
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: transparent;
    color: #0ac4ac;
    transition: all 0.3s;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const TitleBanner = styled.span`
  background: linear-gradient(136.57deg, #0acdf4 -41.4%, rgba(27, 210, 247, 0.0001) 99.15%);
  opacity: 0.9;
  width: 268px;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 37px;
  font: normal 500 18px/22px 'DepotNew';
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 5px;
  @media (max-width: 1050px) {
    margin: 0 auto;
    justify-content: center;
    width: auto;
    max-width: 250px;
    padding: 0;
  }
  @media (max-width: 480px) {
    width: 100% !important;
    max-width: 100% !important;
    font-size: 15px;
    margin-bottom: 0 !important;
  }
`

export const SubtitleBanner = styled.h1`
  font: normal bold 48px/55px 'DepotNew';
  color: #ffffff;
  padding-left: 30px;
  @media (max-width: 1050px) {
    padding-left: 0;
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 40px;
    margin-top: 20px;
  }
`
