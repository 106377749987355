import styled from 'styled-components'

export const StyleBoxVideo = styled.a`
  max-width: 370px;
  height: 403px;
  display: block;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    .info {
      span {
        transform: translateY(0px);
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
  .img_video {
    position: relative;
    background-color: gray;
    border-radius: 12px 0px 12px 0px;
    width: 100%;
    height: 209px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
    overflow: hidden;
    &:before {
      content: '';
      background-color: rgba(0, 149, 218, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    .icone {
      position: absolute;
      z-index: 3;
    }
    .thumb {
      width: 100%;
      flex-grow: 1;
      z-index: 1;
    }
  }
  .info {
    h3 {
      font: normal bold 20px/26px 'DepotNew';
      color: #636466;
      margin-bottom: 10px;
      max-width: 300px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: #94969b;
      margin-bottom: 15px;
    }
    span {
      display: flex;
      align-items: center;
      font-family: DepotNew;
      font-size: 18px;
      color: #0095da;
      transform: translateY(10px);
      opacity: 0;
      transition: all 0.3s;
      img {
        margin-right: 11px;
      }
    }
  }
  @media (max-width: 1050px) {
    .img_video {
      height: auto;
      .thumb {
        max-width: 100%;
      }
    }
    .info {
      span {
        opacity: 1;
      }
    }
  }
`
