import styled from 'styled-components'

export const Navigation = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  li {
    a {
      position: relative;
      width: 294px;
      height: 90px;
      background-color: #0095da;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DepotNew';
      font-size: 18px;
      color: #f5f6fa;
      &:before {
        content: '';
        background-color: #06cfb6;
        border-radius: 0px 0px 6px 6px;
        position: absolute;
        top: 0;
        left: 0;
        width: 294px;
        height: 7px;
        position: absolute;
        opacity: 0;
      }
      &.active {
        background-color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        color: #0095da;
        &:before {
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    li {
      a {
        width: 100%;
        height: 60px;
        font-size: 16px;
        &:before {
          width: 100%;
          height: 4px;
        }
        &.active {
          font-size: 16px;
        }
      }
    }
  }
`
